import React, { useContext, useRef, useEffect } from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
// import { TweenLite, Power3 } from "gsap";

import { Link } from "gatsby";
import {
  GlobalDispatchContext,
  GlobalStateContext,
  ACTIONS,
} from "../context/GlobalContextProvider";

const PrivacyPolicy = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Privacy Policy | Virtual Reality Safety Training'
        description='Privacy Policy Virtual Reality Safety Training'
      />

      <PageBanner title='Privacy Policy' />
      <section
        className='main-container privacy-policy hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col'>
          <div className='column fd-col'>
            <h2 className='main-title primary-color small'>
              Important note concerning data processing in connection with
              Google Analytics
            </h2>
            <p>
              This website uses Google Analytics, a web analytics service
              provided by Google Ireland Limited. If the responsible body for
              the data processing that occurs via this website has their basis
              outside of the European Economic area and Switzerland, then the
              associated Google Analytics data processing is carried out by
              Google LLC. Google Ireland Limited and Google LLC. will
              hereinafter be referred to as “Google”.
            </p>
            <p>
              Google Analytics uses “cookies”, which are text files saved on the
              site visitor’s computer, to help the website analyze their use of
              the site. The information generated by the cookie (including the
              truncated IP address) about the use of the website will normally
              be transmitted to and stored by Google.
            </p>
            <p>
              Google Analytics is used exclusively with the extension
              “_anonymizeIp ()” on this website. This extension ensures an
              anonymization of the IP address by truncation and excludes a
              direct personal reference. Via this extension Google truncates the
              site visitor’s IP address within member states of the European
              Union or other parties to the Agreement on the European Economic
              Area. Only in exceptional situations will the site visitor’s full
              IP address be transmitted to Google servers in the United States
              and truncated there. The IP address, that is provided by the site
              visitor’s browser in using Google Analytics will not be merged by
              Google with other data from Google.
            </p>
            <p>
              On behalf of the site operator, Google will use the information
              collected to evaluate the use of the website, to compile reports
              on website activity and to provide other website and internet
              related services to the site operator (Art. 6 (1)( f) GDPR). The
              legitimate interest in data processing lies in the optimization of
              this website, the analysis of the use of the website and the
              adaptation of the content. The interests of the users are
              adequately protected by the pseudonymization of their data.
            </p>
            <p>
              Google LLC. has certified their compliance with the EU-U.S.{" "}
              <a href='https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI'>
                Privacy Shield
              </a>{" "}
              Framework and on that basis they provides a guarantee to comply
              with European data protection law. The data sent and linked to the
              Google Analytics cookies, e.g. user IDs or advertising IDs will be
              automatically deleted after 50 months. The deletion of data whose
              retention period has been reached is done automatically once a
              month.
            </p>
            <p>
              The website visitor may refuse the use of cookies by selecting the
              appropriate settings in their browser. The website visitor can
              also prevent Google from collecting information (including their
              IP address) via cookies and processing this information by
              downloading this browser plugin and installing it:{" "}
              <a href='http://tools.google.com/dlpage/gaoptout'>
                http://tools.google.com/dlpage/gaoptout
              </a>
            </p>
            <p>
              The website visitor can prevent data collection via Google
              Analytics on this website by clicking{" "}
              <Link
                to='#!'
                onClick={() =>
                  dispatch({ type: ACTIONS.SHOW_COOKIE_PREFERENCES })
                }
              >
                here
              </Link>
              . An “Opt-out Cookie” shall then be applied which shall prevent
              any future collection of the site visitors data when visiting this
              website.
            </p>
            <p>
              Further information concerning data processing and use by Google,
              the settings and deactivation possibilities can be found in the
              Google Privacy Policy (
              <a href='https://policies.google.com/privacy'>
                https://policies.google.com/privacy
              </a>{" "}
              ) as well as in the Google Ads Settings ({" "}
              <a href='https://adssettings.google.com/authenticated'>
                https://adssettings.google.com/authenticated
              </a>{" "}
              ).
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
